/* COLORS*/
.black {
  background: #1f2421;
}

.dark-green {
  background: #253725;
}

.gray {
  background: #bcbcbc;
}

.green {
  background: #2ddc13;
}

.green-hover {
  background: #2ec018;
}

.beige {
  background: #f9f3eb;
}

.sand {
  background: #fcfbf2;
}

.white {
  background: #ffffff;
}

.no-background {
  /*  !*background: url("assets/images/header-girl.png");*!*/
  /*  !*background-repeat: no-repeat;*!*/
  /*  !**!*/
  background: transparent;
}

/* TYPOGRAPHY */

h1,
.h1 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 120%;
}

@media screen and (max-width: 768px) {
  h1,
  .h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 360px) {
  h1,
  .h1 {
    font-size: 28px;
  }
}

h2,
.h2 {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 120%;
}

@media screen and (max-width: 768px) {
  h2,
  .h2 {
    font-size: 24px;
  }
}

@media screen and (max-width: 360px) {
  h2,
  .h2 {
    font-size: 20px;
  }
}

.h3,
h3 {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  line-height: 120%;
}

@media screen and (max-width: 360px) {
  h3,
  .h3 {
    font-size: 14px;
  }
}

.s-button {
  white-space: nowrap;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
}

@media screen and (max-width: 360px) {
  .s-button {
    font-size: 16px;
  }
}

p {
  padding: 0;
  margin: 0;
}

.regular,
p {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 130%;
}

@media screen and (max-width: 768px) {
  .regular,
  p,
  .regular-bold {
    font-size: 16px;
  }
}

.regular-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
}

.plain {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
}

.plain-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120%;
}

.info {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 120%;
}

.info-bold {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 120%;
}

/* BUTTONS */

.button_default-filled {
  text-decoration: none;
  display: flex;
  padding: 18px 32px;
  cursor: pointer;
  min-width: 130px;
  min-height: 64px;
  background: #2ddc13;
  border-radius: 4px;
  border: 1px solid #2ddc13;
  box-sizing: border-box;
  color: #ffffff;
  transition: all 0.2s linear;
}

.button_default-filled:hover {
  background: #2ec018;
}

.button_default {
  text-decoration: none;
  display: flex;
  padding: 16px 24px;
  cursor: pointer;
  min-height: 56px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #1f2421;
  box-sizing: border-box;
  border-radius: 4px;
  color: #1f2421;
  transition: all 0.2s linear;
}

.button_default:hover {
  background: #2ddc13;
  border: 1px solid #2ddc13;
  color: #ffffff;
}

.button_default_with-icon {
  display: flex;
  padding: 16px 24px;
  cursor: pointer;
  width: 146px;
  height: 56px;
  right: 616px;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #1f2421;
  box-sizing: border-box;
  border-radius: 4px;
}

.button_default_with-icon:hover {
  background: #1f2421;
}

.button_default-full-width {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .button_default-filled,
  .button_default {
    height: auto;
    padding: 16px 19px;
  }
}
